/*

? First, let's import the required components.

*/
import { styled, useMediaQuery } from "@mui/system";
import React from "react";
// import Searchbar from "../search/Searchbar";
// import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
	SnackbarOrigin,
	SvgIcon,
	// Snackbar, SnackbarContent,
	Typography,
	useTheme,
} from "@mui/material";
/*

? Next let's import all the UI sub-components we intend to use on this page.

*/
import { useRouter } from "next/router";
import HomeBanner from "../../../public/images/illustrations/index/homepage_banner.svg";
import HomeBannerAr from "../../../public/images/illustrations/index/homepage_banner_arabic.svg";
import HomeBAnnerKn from "../../../public/images/illustrations/index/homepage_banner_kannada.svg";
import StateInterface from "../../../redux-magic/state-interface";

// const Search = dynamic(() => import("./SearchComponent/Search").then((mod) => mod.default));
// import SearchMobile from "./SearchMobileComponent/SearchMobile";

// const OnboardingModal = dynamic(() =>
// 	import("../../modals/OnboardingModal/OnboardingModal").then((mod) => mod.default),
// );
// import Searchbar from "../search/Searchbar";

/*

? Next, let's import the select change event signature.

*/

/*

& Let's define a custom attribute for our container called index. This will help the layout container organise our component correctly.

*/

type IndexAttribute = {
	index?: number;
};

/*

& Now let's style all the components we intend to use on this page.

*/

const Container = styled("div")<IndexAttribute>(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	// alignItems: "flex-end",
	// justifyContent: "space-between",
	width: "100%",
	background: "#fff7f0",
	borderRadius: "0px 0px 30px 30px",
	height: "33vh",
	// paddingBottom: "2.375rem",
	// marginTop: "4rem",
	/* XXS breakpoint */

	/* SM breakpoint */
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
	[theme.breakpoints.between(601, 767)]: { padding: "0rem 3rem 0rem 3rem" },
	/* SMPLUS breakpoint */
	[theme.breakpoints.only("smPlus")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* SM822 breakpoint */
	[theme.breakpoints.only("sm822")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* SM860 breakpoint */
	[theme.breakpoints.only("sm860")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* SM860 breakpoint */
	[theme.breakpoints.only("sm910")]: {
		padding: "0rem 3rem 0rem 3rem",
	},
	/* MD breakpoint */
	[theme.breakpoints.only("md")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* MD1190 breakpoint */
	[theme.breakpoints.only("md1190")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* MD1220 breakpoint */
	[theme.breakpoints.only("md1220")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* LG breakpoint */
	[theme.breakpoints.only("lg")]: {
		padding: "0rem 4rem 0rem 4rem",
	},
	/* LGPLUS breakpoint */
	[theme.breakpoints.up("lgPlus")]: {
		padding: "0rem 5rem 0rem 5rem",
	},
	[theme.breakpoints.up(1500)]: {
		padding: "0rem 10rem 0rem 10rem",
	},
	[theme.breakpoints.up(1640)]: {
		padding: "0rem 15rem 0rem 15rem",
	},
	[theme.breakpoints.up(1780)]: {
		padding: "0rem 20rem 0rem 20rem",
	},
	/* XL breakpoint */
	[theme.breakpoints.up("xl")]: {
		padding: "0rem 23rem 0rem 23rem",
	},
	[theme.breakpoints.up(2020)]: {
		padding: "0rem 26rem 0rem 26rem",
	},
	[theme.breakpoints.up(2120)]: {
		padding: "0rem 29rem 0rem 29rem",
	},
	[theme.breakpoints.up(2220)]: {
		padding: "0rem 32rem 0rem 32rem",
	},
	[theme.breakpoints.up(2350)]: {
		padding: "0rem 39rem 0rem 39rem",
	},
	/* XXL breakpoint */
	[theme.breakpoints.up("xxl")]: {
		padding: "0rem 36rem 0rem 36rem",
	},
	[theme.breakpoints.up(2800)]: {
		padding: "0rem 39rem 0rem 39rem",
	},
	[theme.breakpoints.up(2900)]: {
		padding: "0rem 40rem 0rem 40rem",
	},
	[theme.breakpoints.up(3000)]: {
		padding: "0rem 42rem 0rem 42rem",
	},
	[theme.breakpoints.up(3100)]: {
		padding: "0rem 47rem 0rem 47rem",
	},
	[theme.breakpoints.up(3200)]: {
		padding: "0rem 52rem 0rem 52rem",
	},
	[theme.breakpoints.up(3300)]: {
		padding: "0rem 57rem 0rem 57rem",
	},
	[theme.breakpoints.up(3400)]: {
		padding: "0rem 62rem 0rem 62rem",
	},
	[theme.breakpoints.up(3500)]: {
		padding: "0rem 67rem 0rem 67rem",
	},
	/* XXXL breakpoint */
	[theme.breakpoints.up("xxxl")]: {
		padding: "0rem 72rem 0rem 72rem",
	},
}));

const TypographyHeading = styled(Typography)(({ theme }) => ({
	display: "iunline-flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: "2.5rem",
	fontWeight: 400,
	color: "#000",
	[theme.breakpoints.down("md")]: {
		fontSize: "1.5rem",
	},
}));

/*

* The Magic Component

*/

// const MagicComponent = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "column",
// 	opacity: 1,
// 	zIndex: 15,
// }));

// const SnackbarDiv = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	alignItems: "center",
// 	justifyContent: "center",
// 	gap: "8px",
// }));
interface State extends SnackbarOrigin {
	open: boolean;
}
/*

& Next, let's create the component.

*/

const HomeHeader = ({
	index,
	dispatch,
	// session,
	// onBoardingDetails,
	// onBoardingTitle,
	// new_business_profile_url,
	search_filter_state,
	searchanimationsettings,
	// search_filters_metrices,
}: {
	index: number;
	// session: any;
	dispatch: Function;
	// onBoardingDetails: StateInterface["on_boarding_details"];
	// onBoardingTitle: StateInterface["on_boarding_title"];
	// new_business_profile_url: StateInterface["new_business_profile_url"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	// search_filters_metrices: StateInterface["search_filter_metrices"];
}) => {
	const theme = useTheme();
	const router = useRouter();

	// const [animationComplete, setAnimationComplete] = React.useState(false);

	// React.useEffect(() => {
	// 	if (searchanimationsettings.StartAnimate) {
	// 		setAnimationComplete(true);
	// 	} else {
	// 		setAnimationComplete(false);
	// 	}
	// }, [searchanimationsettings.StartAnimate]);

	const isIPadPro = useMediaQuery("(min-height: 1366px)");

	let label = "HSR Layout";
	let lat = 12.9121181;
	let lon = 77.6445548;

	const defaultUrlpath = encodeURIComponent(
		`${process.env.PRODUCTION_URL || ""}/search?search_type=properties&location=${label},${lon},${lat}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${"en-IN"}`,
	);

	return (
		<React.Fragment>
			{/* {!animationComplete && !searchanimationsettings.StartAnimate ? ( */}
			<Container
				index={index}
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "row",
						width: "30%",
						marginTop: "1rem",
					}}
				>
					<TypographyHeading>
						{router.locale === "ar-AE"
							? `عزّز عملية البحث عن العقارات الخاصة بك مع `
							: router.locale === "kn-IN"
								? `ಬೀಗ್ರು ಮೂಲಕ ನಿಮ್ಮ ಆಸ್ತಿ ಹುಡುಕಾಟವನ್ನು ಟರ್ಬೋಚಾರ್ಜ್ ಮಾಡಿ`
								: `Find, Buy, Sell with `}
						<strong style={{ color: "#FC8019", fontWeight: 600 }}>
							{router.locale === "ar-AE" ? "بيغرو" : "Beegru"}
						</strong>
					</TypographyHeading>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						width: "80%",
					}}
				>
					<SvgIcon
						component={router.locale === "ar-AE" ? HomeBannerAr : router.locale === "kn-IN" ? HomeBAnnerKn : HomeBanner}
						viewBox="0 0 325 136"
						fontSize="small"
						sx={{ display: "flex", width: "80%", height: "80%", justifyContent: "flex-end", alignItems: "flex-end" }}
						onClick={() => {
							window.location.href = `/loading.html?callbackUrl=${defaultUrlpath}`;
						}}
					/>
				</div>
			</Container>
			{/* ) : null} */}
		</React.Fragment>
	);
};

export default HomeHeader;
{
	/* <Container index={index}>
				<Searchbar
					index={0}
					session={session}
					theme={theme}
					dispatch={dispatch}
					lookingFor={lookingFor}
					isSearching={is_searching}
					updateReduxState={true}
					updateQueryString={false}
					enableSearchButtonHits={true}
					alwaysShowFilterButton={false}
					alwaysHideFilterButton={true}
					numberSystem={"international"}
					filtersDialogStateFromSearchPage={false}
					onChangeFiltersDialogState={false}
					searchFiltersAccordionsOrder={searchFiltersAccordionsOrder}
					includeAllResultsOption={false}
					homeLocation={homeLocation}
					homePage
				/>

			</Container> */
}

{
	/*

		<MagicComponent
						sx={{
							[theme.breakpoints.down("sm")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "50px",
								width: "82.5%",
							},
							[theme.breakpoints.down("smPlus")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "60px",
								width: "84.5%",
							},
							[theme.breakpoints.down("sm822")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "70px",
								width: "86.5%",
							},
							[theme.breakpoints.down("sm860")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "87.5%",
							},
							 Asus ZenBook Fold
							[theme.breakpoints.down("sm910")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							z fold landscape
							[theme.breakpoints.down("md")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "120px",
								width: "88%",
							},
							[theme.breakpoints.down("md1190")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "110px",
								width: "89.5%",
							},

							[theme.breakpoints.down("md1220")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "100px",
								width: "90%",
							},
							[theme.breakpoints.down("lg")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							[theme.breakpoints.down(theme.breakpoints.values.lgPlus + 3)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "90px",
								width: "90%",
							},
							[theme.breakpoints.down("lgPlus")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							[theme.breakpoints.down(theme.breakpoints.values.lgPlus + 171)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "60px",
								width: "90%",
							},
							[theme.breakpoints.down("xl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "60px",
								width: "90%",
							},
							[theme.breakpoints.only("xl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "61.69%",
							},
							[theme.breakpoints.only("xxl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "100px",
								width: "55%",
							},
							[theme.breakpoints.only("xxxl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "125px",
								width: "40%",
							},
						}}
					>
						<Search
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
							setState={setState}
						/>
					</MagicComponent>



	*/
}
{
	/* {isMobile ? (
						<SearchMobile
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
							search_filters_metrices={search_filters_metrices}
						/>
					) : null} */
}
{
	/* {session && session.user && session.user.dbUser && session.user.dbUser.onboarding_complete === false ? (
						<OnboardingModal
							session={session}
							dispatch={dispatch}
							onBoardingDetails={onBoardingDetails}
							onBoardingTitle={onBoardingTitle}
							displayProgress={false}
							new_business_profile_url={new_business_profile_url}
						/>
					) : null} */
}
