/*

? First, let's import React and Theme.

*/

import { styled, Theme } from "@mui/material/styles";
import React from "react";
/*

? Next, let's import Layout from the layout-components folder.

*/
import Layout from "../components/layout-components/Layout";
/*

? Next, let's import redux realted components.

	*/
import { connect } from "react-redux";
import StateInterface from "../redux-magic/state-interface";
import {
	fetchHomePageFeaturedPropertiesThunk,
	fetchHomePageHotPropertiesThunk,
	fetchHomePageTopBusinessProfilesThunk,
	fetchHomePageTopServicesThunk,
	fetchSearchThunk,
	setSearchAnimationDetailsThunk,
	updateReduxSessionThunk,
	updateSearchFilterSelectedOptionsThunk,
	wrapper,
} from "../redux-magic/store";
import {
	ClickerType,
	ClickInterface,
	MousePositionType,
	QueryStringParameterType,
	TargetType,
} from "../redux-magic/sub-interfaces/click-interface";
import { SearchFilterId, SearchFilterOptionValueLocation } from "../redux-magic/sub-interfaces/search-types";
import {
	bottom_subscribe_buy_beegru_points,
	homePage as home_page,
	notifications_page,
	search_animation_settings,
} from "../redux-magic/sub-state-data/sub-state-data";
import { updateSearchFilterAppliedThunk, updateSearchFilterVisibilityThunk } from "../redux-magic/thunks";

/*

? Next, let's import the components that we intend to use on this page i.e(SectionFive, SectionSix, SectionThree, SectionSeven, CardCarousel, HomeHeader)

*/
import { useInView } from "react-intersection-observer";
import HomePageMobileLoading from "../components/cards/skeletonLoadingCard/HomePageMobileLoading";
import SearchDesktopLayoutSkeleton from "../components/cards/skeletonLoadingCard/SearchDesktopLayoutSkeleton";
import Fabs from "../components/page-sections/Fabs/Fabs";
import BannerCarouselOne from "../components/page-sections/Index/BannerCarouselOne";
import BannerDuoDesktopOne from "../components/page-sections/Index/BannerDuoDesktopOne";
import CardCarousel from "../components/page-sections/Index/CardCarousel";
import CategorySection from "../components/page-sections/Index/CategorySection";
import CoverBannerSection from "../components/page-sections/Index/CoverBannerSection";
import HomeHeader from "../components/page-sections/Index/HomeHeader";
import ServicesSection from "../components/page-sections/Index/ServicesSection";
import FilterComponents from "../components/search/FilterComponents";

/* Library Functions */

import { useMediaQuery } from "@mui/material";
import { ObjectId } from "bson";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import SearchMobileLayoutSkeleton from "../components/cards/skeletonLoadingCard/SearchMobileLayoutSkeleton";
import SearchNew from "../components/page-sections/Index/SearchComponent/SearchNew";
import { validateClientSession } from "../lib/auth/helpers";
import authenticationTokenGenerator from "../lib/authentication_token_generator";
import buildDatabaseQueries from "../lib/data/search-filters/helpers/buildDatabaseQueries";
import RouterQuerySearchFilters from "../lib/RouterQuerySearchFilters";

export const getServerSideProps = wrapper.getServerSideProps((store: any) => async (context: any) => {
	const server_session: any = await authenticationTokenGenerator(context);

	return {
		props: {
			server_session: server_session,
		},
	};
});

const MainDiv = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		paddingTop: "4rem",
	},
	[theme.breakpoints.up("sm")]: {
		paddingTop: "5rem",
	},
}));

const MobileSkeletonDiv = styled("div")(({ theme }) => ({
	width: "100%",
	paddingTop: "4rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));
const DesktopSkeletonDiv = styled("div")(({ theme }) => ({
	display: "flex",
	paddingTop: "5rem",
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

/*

* HomePageProps

*/

type HomePageProps = {
	searchanimationsettings: StateInterface["search_animation_settings"];
	theme?: Theme;
	data: any[];
	homeLoading: StateInterface["home_loading"];
	profile_context: StateInterface["profile_context"];
	dispatch: Function;
	redux_session: StateInterface["redux_session"];
	refValue: any;
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	newServiceData: StateInterface["new_service_data"];
	search_filter_state: StateInterface["search_filters_state"];
	searchLocation?: any;
	mediaQuery?: any;
	RecentSearchData: any;
};
/*

* Component to render functionality for home page

*/

const HomeDesktopRender: React.FC<HomePageProps> = ({
	searchanimationsettings,
	// theme,
	data,
	homeLoading,
	profile_context,
	dispatch,
	redux_session,
	refValue,
	new_listing_url,
	newPropertyData,
	newServiceData,
	searchLocation,
	search_filter_state,
	mediaQuery,
	RecentSearchData,
}) => {
	return (
		<div style={{ display: mediaQuery ? "none" : "", paddingBottom: "2rem" }}>
			{/* Categories */}

			<SearchNew
				search_filter_state={search_filter_state}
				dispatch={dispatch}
				searchanimationsettings={searchanimationsettings}
			/>

			<CategorySection
				searchanimationsettings={searchanimationsettings ? searchanimationsettings : search_animation_settings}
				dispatch={dispatch}
				searchLocation={searchLocation}
			/>

			{/* Featured Properties */}

			<CardCarousel
				data={data[3]}
				loading={homeLoading.loading_featured_properties ? homeLoading.loading_featured_properties : false}
				type="featured_properties"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[3]}
			/>

			{/* Banner Section One */}

			<BannerDuoDesktopOne
				session={redux_session}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				newPropertyData={newPropertyData}
				newServiceData={newServiceData}
			/>

			{/* Top Properties */}

			{/* <CardCarousel
				data={data[0]}
				loading={homeLoading.loading_hot_properties ? homeLoading.loading_hot_properties : false}
				type="top_properties"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[0]}
			/> */}

			{/* Top Realtors */}

			<CardCarousel
				data={data[1]}
				loading={homeLoading.loading_top_business_profiles as boolean}
				type="top_realtors"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[1]}
			/>

			{/* Banner Section Two */}

			{/* <BannerDuoDesktopTwo
				session={redux_session}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				newPropertyData={newPropertyData}
				newServiceData={newServiceData}
			/> */}

			{/* Explore Services */}

			<ServicesSection
				dispatch={dispatch}
				search_filter_state={search_filter_state}
				searchanimationsettings={searchanimationsettings}
				searchLocation={searchLocation}
			/>

			{/* Top Services */}

			<CardCarousel
				data={data[2]}
				loading={homeLoading.loading_top_services as boolean}
				type="top_services"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[2]}
			/>
		</div>
	);
};

const HomeMobileRender: React.FC<HomePageProps> = ({
	searchanimationsettings,
	data,
	homeLoading,
	profile_context,
	dispatch,
	redux_session,
	refValue,
	new_listing_url,
	newPropertyData,
	search_filter_state,
	searchLocation,
	newServiceData,
	RecentSearchData,
}) => {
	return (
		<div
			style={{
				margin: "0rem 0rem 2rem 0rem",
			}}
		>
			{/* Cover Banner Section Mobile */}

			<CoverBannerSection />

			{/* {RecentSearchData !== null && redux_session && RecentSearchData.length > 0 && (
				<RecentSearch recentSearchData={RecentSearchData} />
			)} */}
			{/* Categories Mobile */}

			<CategorySection
				searchanimationsettings={searchanimationsettings ? searchanimationsettings : search_animation_settings}
				dispatch={dispatch}
				searchLocation={searchLocation}
			/>

			{/* Banner Carousel One Mobile */}

			<BannerCarouselOne
				session={redux_session}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				newPropertyData={newPropertyData}
				newServiceData={newServiceData}
			/>

			{/* Explore Services Mobile */}

			<ServicesSection
				dispatch={dispatch}
				search_filter_state={search_filter_state}
				searchanimationsettings={searchanimationsettings}
				searchLocation={searchLocation}
			/>

			{/* Featured Properties Mobile */}

			<CardCarousel
				data={data[3]}
				loading={homeLoading.loading_featured_properties as boolean}
				type="featured_properties"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[3]}
			/>

			{/* Top Realtors Mobile */}

			<CardCarousel
				data={data[1]}
				loading={homeLoading.loading_top_business_profiles as boolean}
				type="top_realtors"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[1]}
			/>

			{/* Top Properties Mobile */}

			{/* <CardCarousel
				data={data[0]}
				loading={homeLoading.loading_hot_properties ? homeLoading.loading_hot_properties : false}
				type="top_properties"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[0]}
			/> */}

			{/* Banner Carousel Two */}

			{/* <BannerCarouselTwo
				session={redux_session}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				newPropertyData={newPropertyData}
				newServiceData={newServiceData}
			/> */}

			{/* Top Services Mobile */}

			<CardCarousel
				data={data[2]}
				loading={homeLoading.loading_top_services as boolean}
				type="top_services"
				profile_context={profile_context}
				dispatch={dispatch}
				session={redux_session}
				innerRef={refValue[2]}
			/>
		</div>
	);
};

const Home = ({
	server_session,
	// session,
	redux_session,
	homePage,
	// lookingFor,
	// is_searching,
	dispatch,
	new_listing_url,
	theme,
	showInactivityDialog,
	profile_context,
	new_business_profile_url,
	// user_location,
	homeLoading,
	homeLocation,
	notification_data,
	newNotification,
	// transaction_type,
	// onBoardingDetails,
	// onBoardingTitle,
	search_filter_state,
	searchanimationsettings,
	search_filters_metrices,
	search_api_response,
	newPropertyData,
	newServiceData,
	// loading,
	bottom_buy_subscription,
}: {
	server_session: any;
	// session: any;
	redux_session: StateInterface["redux_session"];
	homePage: StateInterface["homePage"];
	// lookingFor: StateInterface["looking_for"];
	// is_searching: StateInterface["is_searching"];
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	theme: Theme;
	showInactivityDialog: boolean;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	// user_location: StateInterface["user_location"];
	homeLoading: StateInterface["home_loading"];
	homeLocation: StateInterface["home_location"];
	notification_data: StateInterface["notifications_page"];
	newNotification?: StateInterface["notification"] | null;
	// transaction_type: StateInterface["transaction"];
	// onBoardingDetails: StateInterface["on_boarding_details"];
	// onBoardingTitle: StateInterface["on_boarding_title"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	search_api_response: StateInterface["SeacrhApiResponse"];
	newPropertyData: StateInterface["new_property_data"];
	newServiceData: StateInterface["new_service_data"];
	// loading: StateInterface["loading"];
	bottom_buy_subscription: StateInterface["bottom_subscribe_buy_beegru_points"];
}) => {
	const router = useRouter();

	const [filterLoading, setFilterLoading] = React.useState(true);

	/*

	* Setting localstorage if location.is appl true


	*/

	const [searchLocation, setSearchLocation] = React.useState<any>(null);

	const [initialLoad, setInitialLoad] = React.useState(true);

	const [mouseScreenX, setMouseScreenX] = React.useState<number>(0);
	const [mouseScreenY, setMouseScreenY] = React.useState<number>(0);
	const [mouseClientX, setMouseClientX] = React.useState<number>(0);
	const [mouseClientY, setMouseClientY] = React.useState<number>(0);
	const [mousePageX, setMousePageX] = React.useState<number>(0);
	const [mousePageY, setMousePageY] = React.useState<number>(0);
	const [mouseOffsetX, setMouseOffsetX] = React.useState<number>(0);
	const [mouseOffsetY, setMouseOffsetY] = React.useState<number>(0);

	React.useEffect(() => {
		const trackMousePosition = (event: any) => {
			setMouseScreenX(event.screenX);
			setMouseScreenY(event.screenY);
			setMouseClientX(event.clientX);
			setMouseClientY(event.clientY);
			setMousePageX(event.pageX);
			setMousePageY(event.pageY);
			setMouseOffsetX(event.offsetX);
			setMouseOffsetY(event.offsetY);
		};

		const storeClick = async (click: ClickInterface) => {
			await fetch("/api/click-catcher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(click),
			});
		};

		const trackClick = (event: any) => {
			const click_id: string = new ObjectId().toString();
			const click_clock: Date = new Date();
			const path: string = window.location.pathname;
			const query_string_parameters: Array<QueryStringParameterType> = window.location.search
				? window.location.search
						.substring(1)
						.split("&")
						.map((param: any) => {
							const key: string = param.split("=")[0];
							const value: string = decodeURIComponent(param.split("=")[1]);
							return { key, value };
						})
				: [];
			const mouse_position: MousePositionType = {
				screen_x: event.screenX,
				screen_y: event.screenY,
				client_x: event.clientX,
				client_y: event.clientY,
				page_x: event.pageX,
				page_y: event.pageY,
				offset_x: event.offsetX,
				offset_y: event.offsetY,
			};
			const target: TargetType = {
				element: event.target.outerHTML.toString() || "",
				element_id: event.target.id || "",
				element_type: event.target.type || event.target.tagName.toLowerCase() || "",
			};
			const clicker: ClickerType = {
				is_signed_in: server_session ? (server_session.user ? true : false) : false,
				user_id: server_session ? (server_session.user ? server_session.user.dbUser._id : click_id) : click_id,
				user_agent: navigator.userAgent,
				ip_address: "0.0.0.0",
			};
			const click: ClickInterface = {
				_id: click_id,
				created_at: click_clock,
				metadata: {
					_id_copy: click_id,
					path: path,
					query_string_parameters: query_string_parameters,
					mouse_position: mouse_position,
					target: target,
					clicker: clicker,
					updated_at: click_clock,
				},
			};
			/* if no id lets not store it  */
			if (click?.metadata?.target.element_id !== "") {
				storeClick(click);
			}
		};

		document.addEventListener("mousemove", trackMousePosition);
		document.addEventListener("mouseenter", trackMousePosition);
		document.addEventListener("mouseleave", trackMousePosition);
		document.addEventListener("click", trackClick);
		return () => {
			document.removeEventListener("mousemove", trackMousePosition);
			document.removeEventListener("mouseenter", trackMousePosition);
			document.removeEventListener("mouseleave", trackMousePosition);
			document.removeEventListener("click", trackClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		/* Storing & Fetching user location from local storage */

		const search_location_local = search_filter_state?.location.selected_options[0];

		const storeInLocalStorage = (key: any, data: any) => {
			localStorage.setItem(key, JSON.stringify(data));
		};

		if (
			typeof window !== "undefined" &&
			search_filter_state?.location.is_applied &&
			!initialLoad &&
			search_location_local !== undefined
		) {
			storeInLocalStorage("search_location", search_location_local);
		}

		const fetchFromLocalStorage = (key: any) => {
			const storedData = localStorage.getItem(key);

			return storedData ? setSearchLocation(JSON.parse(storedData)) : null;
		};

		if (typeof window !== "undefined" && !search_filter_state?.location.is_applied) {
			fetchFromLocalStorage("search_location");
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [search_filter_state?.location.selected_options[0]]);

	/*

	* routerURLConfig here genrates a url from search_filters_state.isapplied is true and pushin the same to the url

	*/
	const routerURLConfig = (search_filter_state: StateInterface["search_filters_state"]) => {
		const appliedFilters = Object.keys(search_filter_state).filter(
			(key) => search_filter_state[key as SearchFilterId]?.is_applied,
		);
		if (appliedFilters.length > 0) {
			const queryParams = appliedFilters.reduce(
				(query, key) => {
					const { option_type, selected_options } = search_filter_state[key as SearchFilterId] || {};
					const selectedOption = selected_options?.[0]?.value;

					switch (option_type) {
						case "text":
							if (Array.isArray(selected_options) && selected_options.length > 0) {
								/*

								 * Extract all values and join them with commas
								*/

								query[key] = selected_options.map((option) => option.value).join(",");
							}

							break;
						case "range":
							if (
								selectedOption &&
								typeof selectedOption === "object" &&
								"min" in selectedOption &&
								"max" in selectedOption
							) {
								query[key] = `${selectedOption.min}-${selectedOption.max}`;
							}
							break;
						case "location":
							if (selectedOption && typeof selectedOption === "object" && "coordinates" in selectedOption) {
								const { title, coordinates } = selectedOption as SearchFilterOptionValueLocation;

								query[key] = `${title},${coordinates[0]},${coordinates[1]}`;
							}
							break;
						default:
							break;
					}
					return query;
				},
				{} as Record<string, string>,
			);

			const queryString = new URLSearchParams(queryParams).toString();
			window.history.pushState({}, "", `/search?${queryString}&locale=${router.locale || "en-IN"}`);
		}
	};

	/*

	* fiter url in search bar, using the router query object to get key and value, passing the same key and value to the RouterQuerySearchFilters function

	*/

	const setFilterFromUrl = (query: ParsedUrlQuery) => {
		Object.entries(query).forEach(([key, value]) => {
			if (typeof value === "string") {
				RouterQuerySearchFilters(key, value, dispatch);
			}
		});
	};

	React.useEffect(() => {
		if (searchanimationsettings?.TriggerSearch || (searchanimationsettings?.ResetSearch && !initialLoad)) {
			routerURLConfig(search_filter_state);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, searchanimationsettings, initialLoad]);

	React.useEffect(() => {
		const handlePopState = () => {
			router.push("/", "", { shallow: true });
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					ResetSearch: false,
					HomeAccordion: false,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					OpenLocationDrawer: false,
				}),
			);
		};

		window.addEventListener("popstate", handlePopState);
		return () => window.removeEventListener("popstate", handlePopState);
	}, [router, dispatch]);

	/*

	* Recent Search Data

	*/

	const [RecentSearchData, setRecentSearchData] = React.useState<any>(null);

	// React.useEffect(() => {
	// 	if (!redux_session) return;

	// 	fetch(`${process.env.PRODUCTION_API_URL}recent-search`, {
	// 		method: "POST",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 		},
	// 		body: JSON.stringify({
	// 			sessionId: redux_session?.session_id,
	// 		}),
	// 	})
	// 		.then((res) => res.json())
	// 		.then((data) => {
	// 			setRecentSearchData(data);
	// 		});
	// }, [redux_session]);

	const clearAllFilters = async () => {
		const filtersToClear = Object.keys(search_filter_state).filter((key) => {
			const { is_applied } = search_filter_state[key as SearchFilterId] || {};

			if (is_applied && searchLocation === null) {
				return !["location", "transaction_type", "search_type"].includes(key);
			}
			return false;
		});

		filtersToClear.forEach((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(updateSearchFilterSelectedOptionsThunk({ searchFilterId: filter as SearchFilterId, newOptions: [] }));
		});
	};

	const fetchHomeData = async () => {
		dispatch(
			setSearchAnimationDetailsThunk({
				StartAnimate: false,
				TriggerSearch: false,
				ResetSearch: false,
				HomeAccordion: false,
				PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
				OpenLocationDrawer: false,
			}),
		);

		await clearAllFilters();
		setInitialLoad(false);
	};

	React.useEffect(() => {
		if (
			initialLoad &&
			(router.asPath === "/" ||
				router.asPath.includes("utm") ||
				router.asPath.includes("fb") ||
				router.asPath.includes("create_property=true") ||
				router.asPath.includes("create_service=true") ||
				router.asPath.includes("create_ofb=true"))
		) {
			fetchHomeData();
		}
		if (initialLoad && Object.keys(router.query).length > 0 && router.asPath.includes("/search")) {
			const ClearAllDefaultFilters = () => {
				/*

		* let's get all the applied filters and store it as string array

		*/
				let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
					return search_filter_state[key as SearchFilterId].is_applied === true;
				});

				/*

		* let's map the filters applied and update the search filter applied to false and selected options to empty array

		*/
				filtersapplied.map((filter) => {
					dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: filter as SearchFilterId,
							newOptions: [],
						}),
					);
					dispatch(
						updateSearchFilterVisibilityThunk({
							searchFilterId: filter as SearchFilterId,
							isVisible: false,
						}),
					);
				});
			};
			ClearAllDefaultFilters();
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: true,
					TriggerSearch: true,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					ResetSearch: false,
					HomeAccordion: false,
					OpenLocationDrawer: false,
				}),
			);
			setFilterFromUrl(router.query);
			setInitialLoad(false);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [initialLoad]);

	/* Setting redux_session */

	const [reduxSessionUpdated, setReduxSessionUpdated] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (server_session && validateClientSession(server_session)) {
			dispatch(updateReduxSessionThunk(server_session));
		}
		setReduxSessionUpdated(true);
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [server_session]);

	// React.useEffect(() => {
	// 	if (redux_session) {
	// 		const onboardingFetch = async () => {
	// 			await dispatch(fetchOnBoardingBusinessTitleThunk());
	// 		};

	// 		if (redux_session.user.dbUser.onboarding_complete === false) {
	// 			onboardingFetch();
	// 		}
	// 	}
	// 	/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	// }, [dispatch, redux_session]);

	const { ref: hotproperties_ref, inView: hotproperties_inView } = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const { ref: featuredproperties_ref, inView: featuredproperties_inView } = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const { ref: topbusinessprofiles_ref, inView: topbusinessprofiles_inView } = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});
	const { ref: topservices_ref, inView: topservices_inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	/*

		* Setting the home page state variable to the homePage prop that we received from the redux store

	*/

	const [home__page, setHome__Page] = React.useState(home_page);

	React.useEffect(() => {
		setHome__Page(homePage);
	}, [homePage]);

	/*

  & Next, let's define the state variables that we intend to use on this page i.e(noContain, data, arrangement)

  ^ In no contain, we will remove the usage of the layout component to match the UI. 0th and 9th index of the array will not use the layout component.

  ^ In data, we will store the data that we intend to use on this page.

  ^ In arrangement, we will store the arrangement of the data that we intend to display on the home page.

  */

	const [data, setData] = React.useState<any>([]);

	const refValue = [hotproperties_ref, topbusinessprofiles_ref, topservices_ref, featuredproperties_ref];

	React.useEffect(() => {
		setData([
			home__page?.hot_properties || [],
			home__page?.top_business_profiles || [],
			home__page?.top_services || [],
			home__page?.featured_properties || [],
		]);
	}, [
		home__page?.hot_properties,
		home__page?.top_business_profiles,
		home__page?.top_services,
		home__page?.featured_properties,
	]);

	/*

		* Fetch Featured Properties

	*/

	const fetchHomeFeaturedProperties = async () => {
		await dispatch(
			fetchHomePageFeaturedPropertiesThunk({
				sessionId: redux_session?.session_id || "",
				// looking_for: lookingFor,
				// location: [12.9716, 77.5946],
				transaction_type: "",
			}),
		);
	};

	React.useEffect(() => {
		if (featuredproperties_inView) {
			fetchHomeFeaturedProperties();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [featuredproperties_inView]);

	/*

		* Fetch Top Properties

	*/

	const fetchHomeHotProperties = async () => {
		await dispatch(
			fetchHomePageHotPropertiesThunk({
				sessionId: redux_session?.session_id || "",
				// looking_for: lookingFor,
				// location: [12.9716, 77.5946],
				transaction_type: "",
			}),
		);
	};

	React.useEffect(() => {
		if (hotproperties_inView) {
			fetchHomeHotProperties();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [hotproperties_inView]);

	/*

		* Fetch Top services

	*/

	const fetchHomePageTopServices = async () => {
		await dispatch(
			fetchHomePageTopServicesThunk({
				sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
				// looking_for: lookingFor,
				location: homeLocation,
			}),
		);
	};

	React.useEffect(() => {
		if (topservices_inView) {
			fetchHomePageTopServices();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [topservices_inView]);

	/*

		* Fetch Top Business Profiles

	*/

	const fetchHomePageTopBusinessProfiles = async () => {
		await dispatch(
			fetchHomePageTopBusinessProfilesThunk({
				sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
				// looking_for: lookingFor,
				location: homeLocation,
			}),
		);
	};

	React.useEffect(() => {
		if (topbusinessprofiles_inView) {
			fetchHomePageTopBusinessProfiles();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [topbusinessprofiles_inView]);

	/*

	* Triggering Search Animation only on first click and search Api on every trigger search state true

	*/

	const [page_number, setPageNumber] = React.useState(1);
	const [CollectionName, setCollectionName] = React.useState<string>("");
	const [PropertyIds, setPropertyIds] = React.useState<any[]>([]);

	const search_submit = () => {
		setFilterLoading(true);
		const gg = buildDatabaseQueries(search_filter_state);

		if (gg.collection !== null) {
			setCollectionName(
				search_filter_state?.transaction_type.selected_options[0]?.value === "ofb" ? "ofb" : gg.collection,
			);
		}

		dispatch(
			fetchSearchThunk({
				db_queries: gg,
				page_number: page_number,
				page_size: process.env.SEARCH_RESULTS_DEFAULT_PAGE_SIZE,
				search_filter_metrices: search_filters_metrices,
				session_id: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
				user_id: redux_session ? (redux_session.user ? redux_session.user.dbUser._id : "") : "",
				profile_context: redux_session ? profile_context : null,
			}),
		);
		/*

		* Setting the search animation details to false after the search is triggered.

		*/
		dispatch(
			setSearchAnimationDetailsThunk({
				StartAnimate: searchanimationsettings.StartAnimate,
				TriggerSearch: false,
				PropertySubCategory: searchanimationsettings.PropertySubCategory,
				ResetSearch: false,
				HomeAccordion: false,
				OpenLocationDrawer: false,
			}),
		);

		/*

		* Setting the page number to the next page number after the search is triggered.

		*/
		setPageNumber((prev) => prev + 1);

		setFilterLoading(false);
	};

	/*

	* if Apply filters is clicked, we will trigger the search api and set the page number to 1 and also we need to clear the property ids array to store the new property ids.

	*/
	const [AppliedFiltersPage_number] = React.useState(1);

	// /*

	// * searchanimationsettings?.ResetSearch === false then we will add the new property ids to the existing property ids array.

	// */
	// const [isDistanceAboveThreshold, setIsDistanceAboveThreshold] = React.useState(false);

	// const [IndextoBeStored, setIndextoBeStored] = React.useState(0);

	React.useEffect(() => {
		if (search_api_response && searchanimationsettings?.ResetSearch === false) {
			// 		/*

			// * using a let to store the distance data. if the distance is above 5km then we will store the distance data in this let.

			// */
			// 		let distance = null;

			// 		/*

			// * if isDistanceAboveThreshold is false only then we will store the distance data in the let distance. if true we dont wanna do anything coz its only a 1 time check. this way we can use this as a dependency in the useEffect below. it wont trigger if it changes to true.

			// */
			// 		if (
			// 			(isDistanceAboveThreshold === false &&
			// 				search_api_response &&
			// 				(search_api_response.non_boosted_distance.length ||
			// 					search_api_response.boosted_from_other_locations_distance ||
			// 					search_api_response.boosted_distance)) !== 0
			// 		) {
			// 			/*

			// 			* Combining distances from non_boosted and boosted into one array

			// 			*/
			// 			distance = [
			// 				...search_api_response.non_boosted_distance,
			// 				...search_api_response.boosted_distance,
			// 				...search_api_response?.boosted_from_other_locations_distance,
			// 			];
			// 			/*

			// 			* Checking if any distance is above the threshold. using some() if any one of the distance is above 5000m or 5km we return true and break the loop.

			// 			*/

			// 			distance.some((d, index) => {
			// 				if (d > parseInt(process.env.NEW_SEARCH_DISTANCE_LIMIT || "5000")) {
			// 					/*

			// 					* Calculating the exact index where the kill threshold is and storing banner_location in that exact position.

			// 					*/
			// 					setIndextoBeStored(PropertyIds.length - distance.length);
			// 					setIsDistanceAboveThreshold(true);
			// 					return true;
			// 				}
			// 				return false;
			// 			});
			// 		}

			// /*

			// * using splice to store banner_location. we dont have to remove anything so the 0.

			// */
			// if (isDistanceAboveThreshold && !updatedPropertyIds.includes("banner_location") && PropertyIds.length !== 0) {
			// 	updatedPropertyIds.splice(IndextoBeStored, 0, "banner_location");
			// }
			/*

			* using a set to remove duplicates from the array.

			*/

			setPropertyIds(
				Array.from(
					new Set([
						...PropertyIds,
						...search_api_response.non_boosted,
						...search_api_response.boosted,
						...search_api_response?.boosted_from_other_locations,
					]),
				),
			);
		} else if (PropertyIds.length === 0 && searchanimationsettings?.ResetSearch === true) {
			// 		/*

			// * using a let to store the distance data. if the distance is above 5km then we will store the distance data in this let.

			// */
			// 		let distance = null;

			// 		/*

			// * if isDistanceAboveThreshold is false only then we will store the distance data in the let distance. if true we dont wanna do anything coz its only a 1 time check. this way we can use this as a dependency in the useEffect below. it wont trigger if it changes to true.

			// */
			// 		if (
			// 			isDistanceAboveThreshold === false &&
			// 			search_api_response &&
			// 			(search_api_response.non_boosted_distance.length ||
			// 				search_api_response.boosted_from_other_locations_distance ||
			// 				search_api_response.boosted_distance) !== 0
			// 		) {
			// 			/*

			// 			* Combining distances from non_boosted and boosted into one array

			// 			*/
			// 			distance = [
			// 				...search_api_response.non_boosted_distance,
			// 				...search_api_response.boosted_distance,
			// 				...search_api_response?.boosted_from_other_locations_distance,
			// 			];
			// 			/*

			// 			* Checking if any distance is above the threshold. using some() if any one of the distance is above 5000m or 5km we return true and break the loop.

			// 			*/

			// 			distance.some((d, index) => {
			// 				if (d > parseInt(process.env.NEW_SEARCH_DISTANCE_LIMIT || "5000")) {
			// 					/*

			// 					* Calculating the exact index where the kill threshold is and storing banner_location in that exact position.

			// 					*/
			// 					setIndextoBeStored(PropertyIds.length - distance.length);
			// 					setIsDistanceAboveThreshold(true);
			// 					return true;
			// 				}
			// 				return false;
			// 			});
			// 		}

			// 		if (isDistanceAboveThreshold && !updatedPropertyIds.includes("banner_location")) {
			// 			updatedPropertyIds.splice(IndextoBeStored, 0, "banner_location");
			// 		}
			// /*

			// * using a set to remove duplicates from the array.

			// */
			setPropertyIds(
				Array.from(
					new Set([
						...search_api_response.non_boosted,
						...search_api_response.boosted,
						...search_api_response?.boosted_from_other_locations,
					]),
				),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_api_response]);

	/*

	* will trigger only if ResetSearch is true and the property ids array is empty. that is if thwy click on apply filters.

	*/
	const apply_filters_submit = () => {
		// setFilterLoading(true);
		/*

		* Setting the page number to the next page number after the search is triggered.

		*/

		// setPageNumber(AppliedFiltersPage_number);
		setPropertyIds([]);

		const gg = buildDatabaseQueries(search_filter_state);

		if (gg.collection !== null) {
			setCollectionName(
				search_filter_state?.transaction_type.selected_options[0]?.value === "ofb" ? "ofb" : gg.collection,
			);
		}

		dispatch(
			fetchSearchThunk({
				db_queries: gg,
				page_number: AppliedFiltersPage_number,
				page_size: process.env.SEARCH_RESULTS_DEFAULT_PAGE_SIZE,
				search_filter_metrices: search_filters_metrices,
				session_id: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
				user_id: redux_session ? (redux_session.user ? redux_session.user.dbUser._id : "") : "",
				profile_context: redux_session ? profile_context : null,
			}),
		);

		/*

		* Setting the search animation details to false after the search is triggered and resetsearch to false.

		*/
		dispatch(
			setSearchAnimationDetailsThunk({
				StartAnimate: searchanimationsettings?.StartAnimate,
				TriggerSearch: false,
				PropertySubCategory: searchanimationsettings.PropertySubCategory,
				ResetSearch: false,
				HomeAccordion: false,
				OpenLocationDrawer: false,
			}),
		);
		setPageNumber(2);
		// setFilterLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	React.useEffect(() => {
		if (searchanimationsettings?.ResetSearch === true && searchanimationsettings?.TriggerSearch === true) {
			apply_filters_submit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchanimationsettings]);

	React.useEffect(() => {
		if (searchanimationsettings?.TriggerSearch === true && searchanimationsettings?.ResetSearch === false) {
			search_filter_state?.location.is_applied === false &&
			search_filter_state?.location.selected_options.length === 0 &&
			searchLocation !== null
				? null
				: search_submit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchanimationsettings]);

	const isCreatePage =
		router.asPath === "/" ||
		router.asPath.includes("utm") ||
		router.asPath.includes("fb") ||
		router.asPath.includes("create_property=true") ||
		router.asPath.includes("create_service=true") ||
		router.asPath.includes("create_ofb=true");

	const shouldRenderMobile =
		useMediaQuery(theme.breakpoints.down("sm")) && isCreatePage && !initialLoad && !router.asPath.includes("/search");

	const shouldRenderMobileLoading = useMediaQuery(theme.breakpoints.down("sm")) && isCreatePage;

	const shouldRenderDesktop =
		useMediaQuery(theme.breakpoints.up("sm")) && isCreatePage && !initialLoad && !router.asPath.includes("/search");

	const shouldRenderSearchMobileSkeleton =
		router.asPath.includes("/search") && searchanimationsettings?.StartAnimate && filterLoading;

	const shouldRenderSearchDesktopSkeleton =
		router.asPath.includes("/search") && searchanimationsettings?.StartAnimate && filterLoading;

	const shouldRenderFilters =
		router.asPath.includes("/search") && searchanimationsettings?.StartAnimate && !filterLoading;

	const HomeDesktopRenderMediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

	const mobileNoContain =
		useMediaQuery(theme.breakpoints.down("sm")) && !isCreatePage && !initialLoad && router.asPath.includes("/search");
	return (
		<React.Fragment>
			<Layout
				noContain={mobileNoContain ? [1] : [0]}
				session={redux_session}
				redux_session_updated={reduxSessionUpdated}
				showInactivityDialog={showInactivityDialog}
				theme={theme}
				dispatch={dispatch}
				profile_context={profile_context}
				new_business_profile_url={new_business_profile_url ? new_business_profile_url : ""}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				notifications={notification_data ? notification_data : notifications_page}
				new_notification={newNotification ? newNotification : null}
				newPropertyData={newPropertyData}
				newServiceData={newServiceData}
				search_filter_state={search_filter_state}
				searchanimationsettings={searchanimationsettings}
				searchLocation={searchLocation}
				bottom_buy_subscription={bottom_buy_subscription ? bottom_buy_subscription : bottom_subscribe_buy_beegru_points}
			>
				{isCreatePage && !searchanimationsettings?.StartAnimate && (
					<HomeHeader
						index={0}
						// session={redux_session}
						dispatch={dispatch}
						// onBoardingDetails={onBoardingDetails ? onBoardingDetails : on_boarding_details}
						// onBoardingTitle={onBoardingTitle ? onBoardingTitle : on_boarding_title}
						// new_business_profile_url={new_business_profile_url ? new_business_profile_url : ""}
						search_filter_state={search_filter_state}
						searchanimationsettings={searchanimationsettings ? searchanimationsettings : search_animation_settings}
						// search_filters_metrices={search_filters_metrices}
					/>
				)}
				<div
					style={{
						opacity: searchanimationsettings?.HomeAccordion ? 0.25 : 1,
						color: searchanimationsettings?.HomeAccordion ? "rgba(0, 0, 0, 0.5)" : "transparent",
						pointerEvents: searchanimationsettings?.HomeAccordion ? "none" : "auto",
						height: initialLoad ? "150vh" : "auto",
					}}
				>
					{
						/*

						* Mobile Home Page Render

						*/

						shouldRenderMobile && data.length > 0 ? (
							<HomeMobileRender
								searchanimationsettings={searchanimationsettings}
								theme={theme}
								data={data}
								homeLoading={homeLoading}
								profile_context={profile_context}
								dispatch={dispatch}
								redux_session={redux_session}
								refValue={refValue}
								new_listing_url={new_listing_url}
								newPropertyData={newPropertyData}
								newServiceData={newServiceData}
								search_filter_state={search_filter_state}
								searchLocation={searchLocation}
								RecentSearchData={RecentSearchData}
							/>
						) : shouldRenderMobileLoading ? (
							<HomePageMobileLoading />
						) : (
							shouldRenderSearchMobileSkeleton && (
								<MobileSkeletonDiv>
									<SearchMobileLayoutSkeleton />
								</MobileSkeletonDiv>
							)
						)
					}

					{
						/*

						* Desktop Home Page Render

						*/ shouldRenderDesktop && !router.asPath.includes("/search") ? (
							<HomeDesktopRender
								searchanimationsettings={searchanimationsettings}
								// theme={theme}
								data={data}
								homeLoading={homeLoading}
								profile_context={profile_context}
								dispatch={dispatch}
								redux_session={redux_session}
								refValue={refValue}
								new_listing_url={new_listing_url}
								newPropertyData={newPropertyData}
								newServiceData={newServiceData}
								search_filter_state={search_filter_state}
								searchLocation={searchLocation}
								mediaQuery={HomeDesktopRenderMediaQuery}
								RecentSearchData={RecentSearchData}
							/>
						) : (
							shouldRenderSearchDesktopSkeleton && (
								<DesktopSkeletonDiv>
									<SearchDesktopLayoutSkeleton />
								</DesktopSkeletonDiv>
							)
						)
					}

					{shouldRenderFilters && (
						<MainDiv>
							<FilterComponents
								session={redux_session}
								search_filter_state={search_filter_state}
								dispatch={dispatch}
								profile_context={profile_context}
								searchanimationsettings={searchanimationsettings}
								search_filters_metrices={search_filters_metrices}
								PropertyIds={PropertyIds}
								search_api_response={search_api_response}
								CollectionName={CollectionName}
							/>
						</MainDiv>
					)}
				</div>

				<Fabs
					session={redux_session}
					dispatch={dispatch}
				/>
				{/* </div> */}
			</Layout>
		</React.Fragment>
	);
};

export default connect(
	({
		globalState: {
			homePage,
			// looking_for,
			// is_searching,
			profile_context,
			new_listing_url,
			new_business_profile_url,
			// user_location,
			home_loading,
			home_location,
			notifications_page,
			// transaction,
			// on_boarding_details,
			// on_boarding_title,
			redux_session,
			search_filters_state,
			search_animation_settings,
			search_filter_metrices,
			SeacrhApiResponse,
			new_property_data,
			new_service_data,
			// loading,
			bottom_subscribe_buy_beegru_points,
		},
	}: {
		globalState: {
			homePage: StateInterface["homePage"];
			// // looking_for: StateInterface["looking_for"];
			// is_searching: StateInterface["is_searching"];
			profile_context: StateInterface["profile_context"];
			new_listing_url: StateInterface["new_listing_url"];
			new_business_profile_url: StateInterface["new_business_profile_url"];
			// user_location: StateInterface["user_location"];
			home_loading: StateInterface["home_loading"];
			home_location: StateInterface["home_location"];
			notifications_page: StateInterface["notifications_page"];
			// transaction: StateInterface["transaction"];
			// on_boarding_details: StateInterface["on_boarding_details"];
			// on_boarding_title: StateInterface["on_boarding_title"];
			redux_session: StateInterface["redux_session"];
			search_filters_state: StateInterface["search_filters_state"];
			search_animation_settings: StateInterface["search_animation_settings"];
			search_filter_metrices: StateInterface["search_filter_metrices"];
			SeacrhApiResponse: StateInterface["SeacrhApiResponse"];
			new_property_data: StateInterface["new_property_data"];
			new_service_data: StateInterface["new_service_data"];
			loading: StateInterface["loading"];
			bottom_subscribe_buy_beegru_points: StateInterface["bottom_subscribe_buy_beegru_points"];
		};
	}) => ({
		homePage: homePage,
		// lookingFor: looking_for,
		// is_searching: is_searching,
		profile_context: profile_context,
		new_listing_url: new_listing_url,
		new_business_profile_url: new_business_profile_url,
		// user_location: user_location,
		homeLoading: home_loading,
		homeLocation: home_location,
		notification_data: notifications_page,
		// transaction_type: transaction,
		// onBoardingDetails: on_boarding_details,
		// onBoardingTitle: on_boarding_title,
		redux_session: redux_session,
		search_filter_state: search_filters_state,
		searchanimationsettings: search_animation_settings,
		search_filters_metrices: search_filter_metrices,
		search_api_response: SeacrhApiResponse,
		newPropertyData: new_property_data,
		newServiceData: new_service_data,
		// loading: loading,
		bottom_buy_subscription: bottom_subscribe_buy_beegru_points,
	}),
)(Home);
