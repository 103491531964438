/* Component Imports */

import { styled, SvgIcon } from "@mui/material";
import { useRouter } from "next/router";
import HomeBanner from "../../../public/images/illustrations/index/homepage_banner.svg";
import HomeBannerAr from "../../../public/images/illustrations/index/homepage_banner_arabic.svg";
import HomeBAnnerKn from "../../../public/images/illustrations/index/homepage_banner_kannada.svg";
/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
	margin: "4rem 0rem 0rem 0rem",
	backgroundColor: "#FAFAFA",
}));

const CoverBannerSection = ({}: {}) => {
	const router = useRouter();
	let label = "HSR Layout";
	let lat = 12.9121181;
	let lon = 77.6445548;

	const defaultUrlpath = encodeURIComponent(
		`${process.env.PRODUCTION_URL || ""}/search?search_type=properties&location=${label},${lon},${lat}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${"en-IN"}`,
	);

	return (
		<MainContainer>
			<SvgIcon
				component={router.locale === "ar-AE" ? HomeBannerAr : router.locale === "kn-IN" ? HomeBAnnerKn : HomeBanner}
				viewBox="0 0 325 136"
				fontSize="small"
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
				}}
				onClick={() => {
					window.location.href = `/loading.html?callbackUrl=${defaultUrlpath}`;
				}}
			/>
		</MainContainer>
	);
};

export default CoverBannerSection;
